import { Text, VStack, Box, HStack, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";


const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const Return = () => {

    const [ status, setStatus ] = useState(null);
    const [ customerEmail, setCustomerEmail ] = useState('');
    const [ customerName, setCustomerName ] = useState('');
    const [ startFrom, setStartFrom ] = useState('');
    const [ total, setTotal ] = useState(0.0);
    const [ subtotal, setSubtotal ] = useState(0.0);
    const [ tax, setTax ] = useState(0.0);
    const [ plan, setPlan ] = useState("");
    const [ endDate, setEndDate ] = useState("");
    const [ invoideNumber, setInvoiceNumber ] = useState("");
    const [ usrname, setUsrname ] = useState("");
    const [ pdfUrl, setPdfUrl ] = useState("");

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        const payMethod = urlParams.get('method');
        console.log(payMethod);

        const baseUrl = process.env.REACT_APP_BASE_URL;
        
        const url = payMethod !== null ? baseUrl + `/alipay-session-status?session_id=${sessionId}` : baseUrl + `/session-status?session_id=${sessionId}`;
    
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            setStatus(data.status);
            setCustomerEmail(data.customer_email);
            setCustomerName(data.customer_name);
            const date = new Date(data.start * 1000);
            const startMonth = monthNames[date.getMonth()];
            const startDay = date.getDate();
            const year = date.getFullYear();
            setStartFrom(`${startMonth} ${startDay}, ${year}`);
            const shortPlan = data.plan;
            switch(shortPlan) {
              case "bcy": {
                setPlan("cnnexvpn-basic-yearly");
                const oneYearLater = new Date(date);
                oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
                setEndDate(`${monthNames[date.getMonth()]} ${date.getDate()}, ${oneYearLater.getFullYear()}`);
                break;
              } 
              case "bcm": {
                setPlan("cnnexvpn-basic-monthly");
                const oneMonthLater = new Date(date);
                if (date.getMonth() === 11) {
                  // If the current month is December, set the month to January of the following year
                  oneMonthLater.setMonth(0);
                  oneMonthLater.setFullYear(oneMonthLater.getFullYear() + 1);
                } else {
                  oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
                }
                setEndDate(`${monthNames[oneMonthLater.getMonth()]} ${oneMonthLater.getDate()}, ${oneMonthLater.getFullYear()}`);
                break;
              }
              case "bc2": {
                setPlan("cnnex-basic-2-yearly");
                const twoYearsLater = new Date(date);
                twoYearsLater.setFullYear(twoYearsLater.getFullYear() + 2);
                setEndDate(`${monthNames[twoYearsLater.getMonth()]} ${twoYearsLater.getDate()}, ${twoYearsLater.getFullYear()}`);
                break;
              }
              case "gny": {
                setPlan("cnnexvpn-golden-yearly");
                const oneYearLater = new Date(date);
                oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
                setEndDate(`${monthNames[date.getMonth()]} ${date.getDate()}, ${oneYearLater.getFullYear()}`);
                break;
              }
              case "gnm": {
                setPlan("cnnexvpn-golden-monthly");
                const oneMonthLater = new Date(date);
                if (date.getMonth() === 11) {
                  // If the current month is December, set the month to January of the following year
                  oneMonthLater.setMonth(0);
                  oneMonthLater.setFullYear(oneMonthLater.getFullYear() + 1);
                } else {
                  oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
                }
                setEndDate(`${monthNames[oneMonthLater.getMonth()]} ${oneMonthLater.getDate()}, ${oneMonthLater.getFullYear()}`);
                break;
              }
              case "gn2": {
                setPlan("cnnexvpn-golden-2-yearly");
                const twoYearsLater = new Date(date);
                twoYearsLater.setFullYear(twoYearsLater.getFullYear() + 2);
                setEndDate(`${monthNames[twoYearsLater.getMonth()]} ${twoYearsLater.getDate()}, ${twoYearsLater.getFullYear()}`);
                break;
              }
              case "pmy": {
                setPlan("cnnexvpn-platinum-yearly");
                const oneYearLater = new Date(date);
                oneYearLater.setFullYear(oneYearLater.getUTCFullYear() + 1);
                setEndDate(`${monthNames[date.getMonth()]} ${date.getDate()}, ${oneYearLater.getFullYear()}`);
                break;
              }
              case "pmm": {
                setPlan("cnnexvpn-platinum-monthly");
                const oneMonthLater = new Date(date);
                if (date.getMonth() === 11) {
                  // If the current month is December, set the month to January of the following year
                  oneMonthLater.setMonth(0);
                  oneMonthLater.setFullYear(oneMonthLater.getFullYear() + 1);
                } else {
                  oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
                }
                setEndDate(`${monthNames[oneMonthLater.getMonth()]} ${oneMonthLater.getDate()}, ${oneMonthLater.getFullYear()}`);
                break;
              }
              case "pm2": {
                setPlan("cnnexvpn-platinum-2-yearly");
                const twoYearsLater = new Date(date);
                twoYearsLater.setFullYear(twoYearsLater.getFullYear() + 2);
                setEndDate(`${monthNames[twoYearsLater.getMonth()]} ${twoYearsLater.getDate()}, ${twoYearsLater.getFullYear()}`);
                break;
              }
              case "eey": {
                setPlan("cnnexvpn-exclusive-yearly");
                const oneYearLater = new Date(date);
                oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
                setEndDate(`${monthNames[date.getMonth()]} ${date.getDate()}, ${oneYearLater.getFullYear()}`);
                break;
              }
              case "eem": {
                setPlan("cnnexvpn-exclusive-monthly");
                const oneMonthLater = new Date(date);
                if (date.getMonth() === 11) {
                  // If the current month is December, set the month to January of the following year
                  oneMonthLater.setMonth(0);
                  oneMonthLater.setFullYear(oneMonthLater.getFullYear() + 1);
                } else {
                  oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
                }
                setEndDate(`${monthNames[oneMonthLater.getMonth()]} ${oneMonthLater.getDate()}, ${oneMonthLater.getFullYear()}`);
                break;
              }
              case "ee2": {
                setPlan("cnnexvpn-exclusive-2-yearly");
                const twoYearsLater = new Date(date);
                twoYearsLater.setFullYear(twoYearsLater.getFullYear() + 2);
                setEndDate(`${monthNames[twoYearsLater.getMonth()]} ${twoYearsLater.getDate()}, ${twoYearsLater.getFullYear()}`);
                break;
              }
              default: break;
            };
            setTotal((parseFloat(data.total)/100.0).toFixed(2));
            setSubtotal((parseFloat(data.subtotal)/100.0).toFixed(2));
            setTax((parseFloat(data.tax)/100.0).toFixed(2));
            setInvoiceNumber(data.invoice_no);
            setUsrname(data.user_name);
            setPdfUrl(data.pdf_invoice_url);
          });

    }, []);

    if (status === 'complete') {
        return (
          <div style={{backgroundColor: "#f1f2f9", width: "100%", minHeight: "100vh", minWidth:"640px", display: "flex", 
                       flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
             <VStack align={"start"} spacing={2}>
              <HStack width={"100%"} justifyContent={"space-between"} >
                <Text fontSize={"sm"} >To: {customerName}</Text>
                <Text fontSize={"sm"} >From: Cnnex Limited Company</Text>
              </HStack>
              <VStack width={"100%"} alignItems={"start"} spacing={1}>
                <Text fontSize={"sm"} color={"gray"}>Username: {usrname}</Text>
                <Text fontSize={"sm"} paddingBottom={"10px"} color={"gray"}>Email: {customerEmail}</Text>
              </VStack>

              <VStack width={"380px"} height={"630px"} 
                      backgroundColor={"white"}
                      borderRadius={"3px"}
                      paddingTop={"20px"}
                      justifyContent={"start"}
                      spacing={4}
                      >
                  <HStack width={"80%"} >
                    <Text fontSize={"xl"} as={'b'}>Paid on {startFrom}</Text>
                  </HStack>
                  <Box borderRadius={"7px"} backgroundColor={"green"} marginBottom={"5px"}>
                    <FontAwesomeIcon icon={ faFileInvoiceDollar } color="lightgray" size="3x" />
                  </Box>
                  <Text fontSize={"xs"} color={"gray"}>Invoice Paid</Text>
                  <Text fontSize={"xl"} as={'b'}>US${total}</Text> 
                  <VStack width={"100%"}>
                  <HStack width={"80%"} justifyContent={"space-between"} >
                    <Text fontSize={"xs"} color={"gray"}>Invoice #</Text>
                    <Text fontSize={"xs"} color={"gray"}>{invoideNumber.substring(3).toUpperCase()}</Text>
                  </HStack>
                  <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                  <HStack width={"80%"} justifyContent={"space-between"} >
                    <Text fontSize={"xs"} color={"gray"}>{startFrom} - {endDate}</Text>
                  </HStack>               
                  <HStack width={"80%"} justifyContent={"space-between"} >
                    <Text fontSize={"xs"} >{plan}</Text>
                    <Text fontSize={"xs"} >${subtotal}</Text>
                  </HStack>
                  <HStack width={"80%"} >
                    <Text fontSize={"xs"} color={"gray"}>Qty 1</Text>
                  </HStack>  
                  <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                  <HStack width={"80%"} justifyContent={"space-between"}>
                    <Text fontSize={"xs"} >Subtotal</Text>
                    <Text fontSize={"xs"} >${subtotal}</Text>
                  </HStack>
                  <HStack width={"80%"} justifyContent={"space-between"}>
                    <Text fontSize={"xs"} >Sales Tax – Washington (10.2%)</Text>
                    <Text fontSize={"xs"} >${tax}</Text>
                  </HStack>
                  <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                  <HStack width={"80%"} justifyContent={"space-between"}>
                    <Text fontSize={"xs"} >Total due</Text>
                    <Text fontSize={"xs"} >${total}</Text>
                  </HStack>
                  <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                  <HStack width={"80%"} justifyContent={"space-between"}>
                    <Text fontSize={"xs"} >Amount paid</Text>
                    <Text fontSize={"xs"} >${total}</Text>
                  </HStack>
                  <HStack width={"80%"} justifyContent={"space-between"}>
                    <Text fontSize={"xs"} >Amount remaining</Text>
                    <Text fontSize={"xs"} >$0.00</Text>
                  </HStack>
                </VStack>   
                <HStack width={"80%"} align={"start"} spacing={5} >
                  <Button colorScheme="telegram" height={"30px"} borderRadius={"15px"} fontSize={"12px"}
                          onClick={() => window.open(pdfUrl, "_blank")}
                          >Download Invoice</Button>
                  <Button background="white" height={"30px"} borderRadius={"15px"} 
                          fontSize={"12px"} color={"#0088cc"}
                          border="1px solid #0088cc"
                          onClick={() => window.open("http://localhost:1102")}
                          >Go to Account</Button>
                </HStack> 

              </VStack>
              <HStack width={"100%"} justifyContent={"center"}>
                <Text color={"gray"} style={{fontSize: "10px", cursor: "pointer"}} 
                      onClick={() => window.open("https://stripe.com/invoicing", "_blank")}
                      >Powered by Stripe </Text>
                <Text color={"gray"} style={{fontSize: "10px"}} 
                      >|</Text>
                <Text color={"gray"} style={{fontSize: "10px", cursor: "pointer"}} 
                      onClick={() => window.open("https://stripe.com/legal/consumer", "_blank")}
                      >Terms</Text>
                <Text color={"gray"} style={{fontSize: "10px", cursor: "pointer"}} 
                      onClick={() => window.open("https://stripe.com/privacy", "_blank")}
                      >Privacy</Text>
              </HStack>
              {/* <Text>{pdfUrl}</Text> */}
             </VStack> 
    
          </div>
        );
    }

    return null;

}

export default Return;