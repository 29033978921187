import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useCallback } from 'react';

const publicKey = process.env.REACT_APP_PUBLISH_KEY;
const baseUrl = process.env.REACT_APP_BASE_URL;

const stripePromise = loadStripe(publicKey);

const CheckoutForm = ({ url, serviceId, paymentType, username }) => {
    const params = {
        pricecode: serviceId,
        type: paymentType,
        username: username,
    };

    const queryString = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');

    
    const fullUrl = baseUrl + url + "?" + queryString;
    // console.log(fullUrl);

    const fetchClientSecret = useCallback(async () => {

        // Create a Checkout Session
        const res = await fetch(fullUrl, {
            method: "POST",
        } );
        const data = await res.json();
        return data.clientSecret;
        // eslint-disable-next-line
    }, []);

    const options = { fetchClientSecret };

    return (
        <div>
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options} >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>

        </div>
    )
};

export default CheckoutForm;