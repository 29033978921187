import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HStack, Text, VStack, Box, Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";

const dataConnectorBaseUrl = process.env.REACT_APP_PAYPAL_DATA_CONNECTOR_BASE_URL;

const PaypalOutput = () => {
    const { sub_id, user_name, service_id } = useParams();
    const [ subscriptionResult, setSubscriptionResult ] = useState(null);
    const [ startDate, setStartDate ] = useState("");
    const [ endDate, setEndDate ] = useState("");

    const params = {
        subscription_id: sub_id,
        username: user_name,
        serviceId: service_id,
    };

    const queryString = Object.keys(params).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key])).join('&');
    const fullUrl = dataConnectorBaseUrl + "/query_subscription?" + queryString;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    useEffect(() => {
        fetch(fullUrl, {
            method: "GET",
        }).then(response => {
            if (response.status !== 401) {
                return response.json();
            } else {
                throw new Error("unauthorized");
            }
        }).then(responseResult => {
            // console.log(responseResult);
            setSubscriptionResult(responseResult);
            setStartDate(new Date(responseResult.start_time).toLocaleDateString('en-US', options));
            setEndDate(new Date(responseResult.billing_info.next_billing_time).toLocaleDateString('en-US', options));
        }).catch(err => {
            console.error(err);
        })
        // eslint-disable-next-line
    }, []);
    console.log(subscriptionResult);

    return (
        <div style={{backgroundColor: "#f1f2f9", width: "100%", minHeight: "100vh", minWidth:"640px", display: "flex", 
        flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            {subscriptionResult !== null &&

                <VStack align={"start"} spacing={2}>
                    <HStack width={"100%"} justifyContent={"space-between"} >
                        <Text fontSize={"sm"} >To: {subscriptionResult.subscriber.name.given_name} {subscriptionResult.subscriber.name.surname}</Text>
                        <Text fontSize={"sm"} >From: Cnnex Limited Company</Text>
                    </HStack>
                    <VStack width={"100%"} alignItems={"start"} spacing={1}>
                        <Text fontSize={"sm"} color={"gray"}>Username: {subscriptionResult.user_name}</Text>
                        <Text fontSize={"sm"} paddingBottom={"10px"} color={"gray"}>Email: {subscriptionResult.subscriber.email_address}</Text>
                    </VStack>

                    <VStack width={"380px"} height={"630px"} 
                        backgroundColor={"white"}
                        borderRadius={"3px"}
                        paddingTop={"20px"}
                        justifyContent={"start"}
                        spacing={4}
                        >
                        <HStack width={"80%"} >
                            <Text fontSize={"xl"} as={'b'}>Paid on {startDate}</Text>
                        </HStack>
                        <Box borderRadius={"7px"} backgroundColor={"green"} marginBottom={"5px"}>
                            <FontAwesomeIcon icon={ faFileInvoiceDollar } color="lightgray" size="3x" />
                        </Box>
                        <Text fontSize={"xs"} color={"gray"}>Invoice Paid</Text>
                        <Text fontSize={"xl"} as={'b'}>US${parseFloat(subscriptionResult.billing_info.last_payment.amount.value).toFixed(2)}</Text> 
                        <VStack width={"100%"}>
                        <HStack width={"80%"} justifyContent={"space-between"} >
                            <Text fontSize={"xs"} color={"gray"}>Invoice #</Text>
                            <Text fontSize={"xs"} color={"gray"}>{sub_id.substring(2).toUpperCase() + (100 + subscriptionResult.billing_info.cycle_executions[0].cycles_completed)}</Text>
                        </HStack>
                        <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                        <HStack width={"80%"} justifyContent={"space-between"} >
                            <Text fontSize={"xs"} color={"gray"}>{startDate} - {endDate}</Text>
                        </HStack>               
                        <HStack width={"80%"} justifyContent={"space-between"} >
                            <Text fontSize={"xs"} >{subscriptionResult.plan_name}</Text>
                            <Text fontSize={"xs"} >${subscriptionResult.plan_billing_cycles[0].pricing_scheme.fixed_price.value}</Text>
                        </HStack>
                        <HStack width={"80%"} >
                            <Text fontSize={"xs"} color={"gray"}>Qty 1</Text>
                        </HStack>  
                        <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                        <HStack width={"80%"} justifyContent={"space-between"}>
                            <Text fontSize={"xs"} >Subtotal</Text>
                            <Text fontSize={"xs"} >${subscriptionResult.plan_billing_cycles[0].pricing_scheme.fixed_price.value}</Text>
                        </HStack>
                        <HStack width={"80%"} justifyContent={"space-between"}>
                            <Text fontSize={"xs"} >Sales Tax ({subscriptionResult.taxes.percentage}%)</Text>
                            <Text fontSize={"xs"} >${(parseFloat(subscriptionResult.plan_billing_cycles[0].pricing_scheme.fixed_price.value) * parseFloat(subscriptionResult.taxes.percentage)/100).toFixed(2)}</Text>
                        </HStack>
                        <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                        <HStack width={"80%"} justifyContent={"space-between"}>
                            <Text fontSize={"xs"} >Total due</Text>
                            <Text fontSize={"xs"} >${parseFloat(subscriptionResult.billing_info.last_payment.amount.value).toFixed(2)}</Text>
                        </HStack>
                        <hr style={{ marginTop: "10px", width: '80%', borderColor: "lightgray", marginBottom: "10px" }} />
                        <HStack width={"80%"} justifyContent={"space-between"}>
                            <Text fontSize={"xs"} >Amount paid</Text>
                            <Text fontSize={"xs"} >${parseFloat(subscriptionResult.billing_info.last_payment.amount.value).toFixed(2)}</Text>
                        </HStack>
                        <HStack width={"80%"} justifyContent={"space-between"}>
                            <Text fontSize={"xs"} >Amount remaining</Text>
                            <Text fontSize={"xs"} >${parseFloat(subscriptionResult.billing_info.outstanding_balance.value).toFixed(2)}</Text>
                        </HStack>
                        </VStack>   

                        <HStack width={"80%"} align={"start"} spacing={5} >
                        <Button colorScheme="telegram" height={"30px"} borderRadius={"15px"} fontSize={"12px"}
                                onClick={() => window.open("http://localhost:1102", "_blank")}
                                >Download Invoice</Button>
                        <Button background="white" height={"30px"} borderRadius={"15px"} 
                                fontSize={"12px"} color={"#0088cc"}
                                border="1px solid #0088cc"
                                onClick={() => window.open("http://localhost:1102")}
                                >Go to Account</Button>
                        </HStack> 

                    </VStack>
                </VStack>
            }
        </div>
    );
};



export default PaypalOutput;