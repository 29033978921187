import "./SubCheckOut.css";
import CheckoutForm from "./CheckoutForm";
import { useParams } from "react-router-dom";
import { HStack, Text, VStack, Input, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactComponent as Visa } from "../images/visa-loose.svg";
import { ReactComponent as Master } from "../images/mastercard-loose.svg";
import { ReactComponent as Amex } from "../images/amex-loose.svg";
import { ReactComponent as Discover } from "../images/discover-loose.svg";
import { ReactComponent as JCB } from "../images/jcb.svg";
import { ReactComponent as Unionpay } from "../images/unionpay.svg";
import { ReactComponent as Paypal } from "../images/paypal.svg";
import { ReactComponent as AmazonPay } from "../images/amazon-pay-24.svg";
import bcrypt from "bcryptjs-react";
import usePost from "../hooks/usePost";
import PaypalCheckout from "./PaypalCheckout";


const Logo = require("../logos/logo.ico");
const Alipay = require("../images/alipay_logo.png");

const letterRegex = /^[a-zA-z]/;

var planDetails;
var discount;
var amount;
var originalAmount;
var period;

const plainSecretId = process.env.REACT_APP_PLAIN_SECRET_ID;

const generateRandomString = (len) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~";
    let randomString = "";
    for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
    }
    return randomString;
}


const SubCheckOut = () => {

    const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setScreenWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { priceplan } = useParams();
    const [ isLoginHovered, setIsLoginHovered ] = useState(false);

    const [ isUsrnameInputTouched, setIsUsrnameInputTouched ] = useState(false);
    const [ inputUsrname, setInputUsrName ] = useState("");
    const [ inputUsrnameErrMsg, setInputUsrnameErrMsg ] = useState("");

    const [ isInputPwTouched, setIsInputPwTouched ] = useState(false);
    const [ inputPw, setInputPw ] = useState("");
    const [ inputPwErrMsg, setInputErrMsg ] = useState("");

    const [ isInputConfirmPwTouched, setInputConfirmPwTouched ] = useState(false);
    const [ inputConfirmPw, setInputConfirmPw ] = useState("");
    const [ inputConfirmPwErrMsg, setInputConfirmPwErrMsg ] = useState("");

    const [ signupValid, setSignupValid ] = useState(false);
    const [ signupResultMsg, setSignupResultMsg ] = useState("");

    const [ openPaymentCard, setOpenPaymentCard ] = useState(false);
    const [ openAlipayment, setOpenAlipayment ] = useState(false);
    const [ openPaypal, setOpenPaypal ] = useState(false);

    const [ disbale, setDisable ] = useState(false);

    const { isLoading, postResult, submitData } = usePost();
    const [ tempTokenData, setTempTokenData ] = useState(null);

    if (priceplan === "ee2") {
        planDetails="Exclusive Member with 2-year plan";
        discount="43%";
        amount="$551.76";
        originalAmount="$959.76";
        period = "2 years";
    } else if (priceplan === "eey") {
        planDetails="Exclusive Member with 1-year plan";
        discount="17%";
        amount="$399.99";
        originalAmount="$479.88"
        period = "1 year";
    } else if (priceplan === "eem") {
        planDetails="Exclusive Member with monthly plan"
        discount="no discount";
        amount="$39.99";
        originalAmount="$39.99"
        period = "1 month";
    } else if (priceplan === "pm2") {
        planDetails="Platinum Member with 2-year plan";
        discount="30%";
        amount="$167.76";
        originalAmount="$239.76";
        period = "2 years";
    } else if (priceplan === "pmy") {
        planDetails="Platinum Member with 1-year plan";
        discount="17%";
        amount="$99.99";
        originalAmount="$119.88";
        period = "1 year";
    } else if (priceplan === "pmm") {
        planDetails="Platinum Member with monthly plan";
        discount="no discount";
        amount="$9.99";
        originalAmount="$9.99";
        period = "1 month";
    } else if (priceplan === "gn2") {
        planDetails="Golden Member with 2-year plan";
        discount="38%";
        amount="$119.76";
        originalAmount="$191.76";
        period = "2 years";
    } else if (priceplan === "gny") {
        planDetails="Golden Member with 1-year plan";
        discount="17%";
        amount="$79.99";
        originalAmount="$95.88";
        period = "1 year";
    } else if (priceplan === "gnm") {
        planDetails="Golden Member with monthly plan";
        discount="no discount";
        amount="$7.99";
        originalAmount="$7.99";
        period = "1 month";
    } else if (priceplan === "bcy") {
        planDetails="Basic Member with 1-year plan";
        discount="17%";
        amount="$49.99";
        originalAmount="$59.88";
        period = "1 year";
    } else if (priceplan === "bc2") {
        planDetails="Basic Member with 2-year plan";
        discount="20%";
        amount="$95.76"
        originalAmount="$119.76"
        period = "2 years";
    } else if (priceplan === "bcm") {
        planDetails="Basic Member with monthly plan";
        discount="no discount";
        amount="$4.99";
        originalAmount="$4.99";
        period = "1 month";
    }

    const currentMilliseconds = new Date().getTime();
    const salt = bcrypt.genSaltSync(11);
    const hash = bcrypt.hashSync(plainSecretId, salt);
    var shortHash = hash.slice(7) + currentMilliseconds + generateRandomString(10);
    shortHash = shortHash.replaceAll("/", "backslash");
    const header = {
        "Content-Type": "application/json",
        "id": shortHash, 
    };

    // useSubmitData(process.env.REACT_APP_SERVER_BASE_URL + "/api/verify_id/temp_access_token", header, null);


    useEffect(() => {
        const options = {
            method: "POST",
            headers: header,
        };
        const postData = () => {
            fetch(process.env.REACT_APP_SERVER_BASE_URL + "/api/verify_id/temp_access_token", options)
                .then(response => {
                    if (response.status !== 401) {
                        return response.json();
                    } else {
                        throw new Error("unauthorized");
                    }
                }).then(res => {
                    // console.log(res);
                    setTempTokenData(res);
                }).catch(error => setTempTokenData({
                    cstatus: "010",
                    message: "unauthorized: " + error,
                })
            );
        }
        postData();
        // eslint-disable-next-line
    }, []);

    // console.log(tempTokenData);

    const handleUsernameChange = (e) => {
        e.preventDefault();
        setInputUsrName(e.target.value);
        setIsUsrnameInputTouched(false);
    };

    const handleUsernameOnBlur = () => {
        setIsUsrnameInputTouched(true);
    };

    const handlePwChange = (e) => {
        e.preventDefault();
        setInputPw(e.target.value);
        setIsInputPwTouched(false);
    };

    const handlePwOnBlur = () => {
        setIsInputPwTouched(true);
    };

    const handleConfirmPwChange = (e) => {
        e.preventDefault();
        setInputConfirmPw(e.target.value);
        setInputConfirmPwTouched(true);
    };

    const handleConfirmPwOnBlur = () => {
        setInputConfirmPwTouched(true);
    };

    const handleClickContinue = () => {

        if (tempTokenData.cstatus === "003") {
            const head = {
                "Content-Type": "application/json",
                "id": tempTokenData.tempToken,
            };
            const bodyData = {
                "userName": inputUsrname,
                "password": btoa(inputPw),
                "email": "",
                "phone": "",
                "memberType": 1,
            }

            submitData(process.env.REACT_APP_SERVER_BASE_URL + "/api/verify_access_token/safe_signup", head, bodyData);
        }
    };

    const handleClickCardPayement = () => {
        setOpenPaymentCard(!openPaymentCard);
        setOpenAlipayment(false);
        setOpenPaypal(false);
    };

    const handleClickAlipayment = () => {
        setOpenAlipayment(!openAlipayment);
        setOpenPaymentCard(false);
        setOpenPaypal(false);
    }

    const handleClickPaypal = () => {
        setOpenPaypal(!openPaypal);
        setOpenAlipayment(false);
        setOpenPaymentCard(false);
    }

    useEffect(() => {
        if (postResult !== null) {
            if (postResult.cstatus === "003") {
                setSignupValid(true);
                setDisable(true);
                setSignupResultMsg("Sign Up Successfully.");
            } else if (postResult.cstatus === "002") {
                setSignupResultMsg("The user name has been taken already, log in directly if it's your user name.");
            } else if (postResult.cstatus === "010") {
                setSignupResultMsg("Your session was expired, please refresh page and try again.");
            } else {
                setSignupResultMsg("Network error, please try again later");
            }
        }

    }, [postResult])

    useEffect(() => {
        if (isUsrnameInputTouched) {
            if (inputUsrname.length === 0) {
                setInputUsrnameErrMsg("The username can not be empty.");
            } else if (inputUsrname.length < 5) {
                setInputUsrnameErrMsg("The username must be at least 5 charaters.")
            } else if (!letterRegex.test(inputUsrname)) {
                setInputUsrnameErrMsg("The username must begin with a letter.")
            } else {
                setInputUsrnameErrMsg("");
            } 
        }
    }, [isUsrnameInputTouched, inputUsrname]);

    useEffect(() => {
        if (isInputPwTouched) {
            if (inputPw.length === 0) {
                setInputErrMsg("The password can not be empty.");
            } else if (inputPw.length < 8) {
                setInputErrMsg("The password must be at least 8 charaters.");
            } else {
                setInputErrMsg("");
            }
        }
    }, [isInputPwTouched, inputPw]);

    useEffect(() => {
        if (isInputConfirmPwTouched) {
            if (inputConfirmPw !== inputPw) {
                setInputConfirmPwErrMsg("The input passwords are inconsistent.");
            } else {
                setInputConfirmPwErrMsg("");
            }
        }
    }, [isInputConfirmPwTouched, inputConfirmPw, inputPw])

    return (
        <div className="get-deal-page-container">
            <HStack width={ screenWidth > 905 ? "80%" : "90%" }
                    padding={"15px"} 
                    spacing={3} >
                <img src={Logo} width={"40px"} alt="logo" />
                <Text fontSize={"xl"} as={'b'}>CNNEX</Text>
                <Text fontSize={"xl"} >Checkout</Text>
            </HStack>
            <HStack width={ screenWidth > 905 ? "80%" : "90%" }
                    padding={"15px"} >
                <Text>Already have Cnnex account?</Text>
                <Text color={"tomato"} as={'b'}
                      className={`unerline-login-hover ${isLoginHovered ? "hovered" : ""}`}
                      onMouseEnter={() => setIsLoginHovered(true)}
                      onMouseLeave={() => setIsLoginHovered(false)}
                      onClick={() => window.open("https://www.cnnexaccount.com", "_blank")}
                >Log in</Text>
            </HStack>
            {/* <Text>{priceplan}</Text> */}
            <br />
            <HStack width={ screenWidth > 905 ? "80%" : "90%" } 
                    // backgroundColor={"lightblue"}
                    marginBottom={"25px"}
                    >
                <VStack width={"100%"} 
                        alignItems={"start"}
                        >
                    <HStack width={ "100%" }
                        align={"start"}
                        padding={"15px"}>
                        <Text fontSize={"md"}>1. Enter a username & password for your new Cnnex Account</Text>
                    </HStack>
                    <VStack width={"100%"}
                            justifyContent={"start"}
                            padding={"15px"}
                            marginLeft={"15px"}
                            backgroundColor={"white"}
                            borderRadius={"15px"}
                            alignItems={"start"}
                    >
                        <Input type="text" 
                               placeholder="Please input a username with at least 5 characters"
                               value={inputUsrname}
                               disabled={disbale}
                               onChange={ handleUsernameChange } 
                               onBlur={ handleUsernameOnBlur }/>
                        <Text fontSize={"sm"} color={"red"} paddingLeft={"5px"}>{inputUsrnameErrMsg}</Text>
                        <Input type="password" 
                               placeholder="Input password with at least 8 characters"
                               value={inputPw}
                               disabled={disbale}
                               onChange={ handlePwChange }
                               onBlur={ handlePwOnBlur }/>
                        <Text fontSize={"sm"} color={"red"} paddingLeft={"5px"}>{inputPwErrMsg}</Text>
                        <Input type="password" placeholder="Confirm your input password" 
                               value={inputConfirmPw}
                               disabled={disbale}
                               onChange={ handleConfirmPwChange }
                               onBlur={ handleConfirmPwOnBlur} />
                        <Text fontSize={"sm"} color={"red"} paddingLeft={"5px"}>{inputConfirmPwErrMsg}</Text>

                    </VStack>
                    <VStack width={ screenWidth > 905 ? "80%" : "90%" }
                            align={"start"}
                            padding={"15px"}>
                        <Button width={"40%"} colorScheme="telegram"
                                isDisabled={inputUsrnameErrMsg !== "" || inputPwErrMsg !== "" || inputConfirmPwErrMsg !== "" ||
                                inputUsrname === "" || inputConfirmPw === "" || inputPw === "" || disbale }   
                                onClick={handleClickContinue}      
                                isLoading={isLoading}                        
                                >Continue</Button>
                        <Text fontSize={"sm"} color={signupValid ? "green" : "tomato"} paddingLeft={"5px"} as={'b'}>{signupResultMsg}</Text>
                    </VStack>


                    {
                        signupValid && 
                        <VStack width={"100%"} 
                                align={"start"}
                                marginLeft={"15px"}
                             >
                            <HStack width={"100%"}
                                    align={"start"}
                            >
                                <Text fontSize={"md"}>2. Select a payment method</Text>
                            </HStack>
                            {/* <br /> */}
                            <VStack width={ "100%" } 
                                    spacing={5}>
                                <VStack width={"100%"} 
                                        backgroundColor={"white"}
                                        borderRadius={"15px"}
                                        padding={"15px"}
                                        align={"start"} 
                                        border={"1px solid #aaaaaa"}
                                        onClick={handleClickCardPayement}
                                        >
                                    <HStack width={"100%"}>
                                        <HStack width={"100%"}
                                                spacing={2}
                                                justifyContent={"start"} >
                                            <Text fontSize={"sm"}> { screenWidth > 905 ? "Credit or debit card" : "Card" }</Text>
                                            <Visa />
                                            <Master />
                                            <Amex />
                                            <Discover />
                                            <JCB />
                                            <Unionpay />
                                        </HStack>
                                        <HStack>
                                            {openPaymentCard ? 
                                            <Text fontSize={"sm"} color={'b'}>&#10094;</Text>
                                            :
                                            <Text fontSize={"sm"}>&#10095;</Text> }
                                        </HStack>
                                    </HStack>
                                    <div style={{ width: "100%" }} >
                                    {openPaymentCard &&
                                     <div>
                                        <CheckoutForm  url={"/create-checkout-session"} serviceId={priceplan} paymentType={"SUB"} username={inputUsrname}/>
                                        <Text marginTop={"7px"} marginBottom={"7px"} fontSize={"xs"} color={"red"}>
                                            Services are subscription based. After {period}, the subscription will automatically renew annually for an additional {period} term, and you’ll be charged the then-applicable renewal price unless you cancel it. 
                                            </Text>
                                            <Text fontSize={"xs"} color={"gray"} marginBottom={"7px"} >
                                             Price is subject to change, but we’ll always send you a notification email prior to charging. You can manage your subscription (extend, upgrade, cancel auto-renewal) at any time from your Cnnex Account using our instructions.
                                            </Text>
                                            <Text fontSize={"xs"} color={"gray"}>
                                            By purchasing this service and clicking "Pay", you agree to the terms of service, auto-renewal terms, electronic document delivery, and acknowledge the privacy policy.
                                        </Text>
                                    </div>
                                    }
                                    </div>
                                </VStack>
                                <VStack width={"100%"}
                                        backgroundColor={"white"}
                                        borderRadius={"15px"}
                                        padding={"15px"}
                                        align={"start"} 
                                        border={"1px solid #aaaaaa"}
                                        onClick={handleClickPaypal}
                                        >
                                    <HStack justifyContent={"space-between"}
                                            width={"100%"} >
                                        <HStack width={"100%"}
                                                spacing={2}
                                                justifyContent={"start"} >
                                            <Text fontSize={"sm"}>Paypal</Text>
                                            <Paypal />
                                        </HStack>
                                        <HStack>
                                            {openPaypal ? 
                                                <Text fontSize={"sm"} color={'b'}>&#10094;</Text>
                                                :
                                                <Text fontSize={"sm"}>&#10095;</Text> }                                    
                                        </HStack>

                                    </HStack>

                                    <div style={{ width: "100%", paddingTop: "15px" }}>
                                        {openPaypal && 
                                            <div>
                                                <PaypalCheckout serviceId={priceplan} userName={inputUsrname}/>
                                            </div>
                                        }
                                    </div>
                                    <div style={{ width: "100%" }} >
                                            {openPaypal &&
                                            <div>
                                                <Text marginTop={"7px"} marginBottom={"7px"} fontSize={"xs"} color={"red"}>
                                                    Services are subscription based. After {period}, the subscription will automatically renew annually for an additional {period} term, and you’ll be charged the then-applicable renewal price unless you cancel it. 
                                                    </Text>
                                                    <Text fontSize={"xs"} color={"gray"} marginBottom={"7px"} >
                                                    Price is subject to change, but we’ll always send you a notification email prior to charging. You can manage your subscription (extend, upgrade, cancel auto-renewal) at any time from your Cnnex Account using our instructions.
                                                    </Text>
                                                    <Text fontSize={"xs"} color={"gray"}>
                                                    By purchasing this service and clicking "Pay", you agree to the terms of service, auto-renewal terms, electronic document delivery, and acknowledge the privacy policy.
                                                </Text>
                                            </div>
                                            }
                                    </div>
                                    
                                </VStack>
                                    <HStack width={"100%"}
                                            backgroundColor={"white"}
                                            borderRadius={"15px"}
                                            padding={"15px"}
                                            align={"start"}
                                            border={"1px solid #aaaaaa"} 
                                            onClick={handleClickAlipayment}>
                                        <HStack width={"100%"}
                                                spacing={2}
                                                justifyContent={"start"}>
                                            <Text fontSize={"sm"}>Alipay</Text>
                                            <img src={Alipay} width="24px" alt="alipay" />
                                        </HStack>
                                        <HStack>
                                                {openAlipayment ? 
                                                <Text fontSize={"sm"} >&#10094;</Text>
                                                :
                                                <Text fontSize={"sm"}>&#10095;</Text> }
                                        </HStack>
                                    </HStack>
                                    <div style={{ width: "100%" }}>
                                        {openAlipayment &&
                                        <div>
                                            <CheckoutForm url={"/create-alipay-checkout-session"} serviceId={priceplan} paymentType={"PAY"} username={inputUsrname}/>
                                            <Text marginTop={"7px"} marginBottom={"7px"} fontSize={"xs"} color={"red"}>
                                            Alipay doesn't support auto-renewal you need to pay off next bill manually after {period} from your Cnnex Account. 
                                            </Text>
                                            <Text fontSize={"xs"} color={"gray"} marginBottom={"7px"} >
                                             Price is subject to change, but we’ll always send you a notification email prior to charging. You can manage your subscription (extend, upgrade, cancel auto-renewal) at any time from your Cnnex Account using our instructions.
                                            </Text>
                                            <Text fontSize={"xs"} color={"gray"}>
                                            By purchasing this service and clicking "Pay", you agree to the terms of service, auto-renewal terms, electronic document delivery, and acknowledge the privacy policy.
                                            </Text>    
                                        </div>}
                                        
                                    </div>

                                <HStack width={"100%"}
                                        backgroundColor={"white"}
                                        borderRadius={"15px"}
                                        padding={"15px"}
                                        align={"start"}
                                        border={"1px solid #aaaaaa"} >
                                    <HStack width={"100%"}
                                            spacing={2}
                                            justifyContent={"start"} >
                                        <Text fontSize={"sm"}>AmazonPay</Text>
                                        <AmazonPay />
                                    </HStack>
                                    <HStack>
                                        <Text fontSize={"sm"}>&#10095;</Text>
                                    </HStack>
                                </HStack>

                            </VStack>
                        </VStack>
                    }

                <HStack width={"100%"}
                        padding={"15px"}>
                    <Text fontSize={"md"}>Order Sumamry</Text>
                </HStack>
                <VStack width={ "100%" }
                        marginLeft={"15px"}
                        backgroundColor={"white"}
                        borderRadius={"15px"}
                        padding={"15px"}
                        align={"start"}
                        border={"1px solid #aaaaaa"}
                        paddingTop={"25px"} 
                        marginBottom={"40px"}>
                    <HStack>
                        <Text fontSize={"xs"} >Plan Details</Text>
                        {
                            discount !== "no discount" ? 
                            <Text fontSize={"xs"} color={"red"}>Saving {discount}</Text> :
                            <Text fontSize={"xs"} color={"red"}>{discount}</Text>
                        }
                        
                    </HStack>
                    
                    <HStack width={"100%"}
                            paddingTop={"15px"} 
                            justifyContent={"space-between"}>
                        <Text fontSize={"sm"} >{planDetails}</Text>
                        <Text fontSize={"sm"} >{amount}</Text>
                    </HStack>
                    <HStack width={"100%"}
                            paddingBottom={"15px"}
                            justifyContent={"space-between"}>
                        {
                            discount !== "no discount" ?
                            <Text fontSize={"sm"} color={"red"}>Saving {discount}</Text> :
                            <Text fontSize={"sm"} color={"red"}>{discount}</Text>
                        }
                        { discount !== "no discount" && <Text fontSize={"sm"} color={"gray"} textDecoration={"line-through"}>{originalAmount}</Text>}
                        
                    </HStack>
                    
                    {/* <hr style={{ width: '100%', borderColor: "gray", marginBottom: "10px" }} /> */}
                </VStack>

                </VStack>

            </HStack>
            <HStack width={screenWidth > 905 ? "80%" : "90%"} paddingLeft={"10px"} position={"absolute"} 
                    justifyContent={"start"} bottom={"25px"}
                    marginTop={"25px"}>
                <Text fontSize={"xs"} >© 2024 Cnnex Limited Company. All rights reserved</Text>

            </HStack>
        </div>
    );

};

export default SubCheckOut;