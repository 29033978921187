import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const usePost = () => {
    const [ postResult, setPostResult ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const submitData = async (url, header, bodyData) => {
        var options = bodyData !== null ? {
            method: "POST",
            headers: header,
            body: JSON.stringify(bodyData),
        } : {
            method: "POST",
            headers: header,
        };

        setIsLoading(true);
        await wait(500);

        fetch(url, options)
            .then(response => {
                if (response.status !== 401) {
                    return response.json();
                } else {
                    throw new Error("unauthorized");
                }
            }).then(responseData => {
                setPostResult(responseData);
            }).catch(error => {
                setPostResult({
                    cstatus: "010",
                    message: "unauthorized: " + error,
                });
            }).finally(() => {
                setIsLoading(false);
            });
    };

    return { isLoading, postResult, submitData };
}

export default usePost;