import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Text } from "@chakra-ui/react";

const client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const checkout_server_base_url = process.env.REACT_APP_PAYPAL_CHECKOUT_SERVER_BASE_URL;

const Message = ({ content }) => {
    return <Text color={"gray"} fontSize={"sm"}>{content}</Text>
};

const PaypalCheckout = (props) => {
    const [ message, setMessage ] = useState("");
    const nav = useNavigate();

    const initialOptions = {
        "client-id": client_id,
        "enable-funding": "paylater,card",
        "disable-funding": "",
        "data-sdk-integration-source": "integrationbuilder_sc",
        vault: "true",
        intent: "subscription",
    }

    const createSubscription = async () => {

        try {
            const response = await fetch(checkout_server_base_url + "/api/paypal/create-subscription", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ 
                    userAction: "SUBSCRIBE_NOW",
                    serviceId: props.serviceId,
                }),
            });
            const data = await response.json();
            if (data?.id) {
                setMessage("Subscribing...");
                return data.id;
            } else {
                console.error({ callback: "createSubscription", serverResponse: data }, JSON.stringify(data, null, 2) );
                const errorDetail = data?.details?.[0];
                setMessage(
                    `Could not initiate PayPal Subscription...<br><br>${
                      errorDetail?.issue || ""
                    } ${errorDetail?.description || data?.message || ""} ` +
                      (data?.debug_id ? `(${data.debug_id})` : ""),
                    { hideButtons: true },
                );
            }
        } catch(error) {
            setMessage(`Could not initiate PayPal Subscription...${error}`);
        }
    };

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <PayPalScriptProvider options={initialOptions} >
                <PayPalButtons 
                    style={{ shape:"rect", layout: "vertical" }}
                    createSubscription={ createSubscription }
                    onApprove={ async (data) => {
                        if (data.orderID) {
                            nav(`/ppoutput/${data.subscriptionID}/${props.userName}/${props.serviceId}`);
                        } else {
                            setMessage(`Failed to activate the subscription: ${data.subscriptionID}`);
                        }
                    }}
                />
            </PayPalScriptProvider>
            <Message content={message} />
        </div>
    );


};

export default PaypalCheckout;