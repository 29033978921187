// import logo from './logo.svg';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import SubCheckOut from './components/SubCheckOut';
import { ChakraProvider } from '@chakra-ui/react';
import Return from './components/Return';
import PaypalOutput from './components/PaypalOutput';


function App() {
  return (
    <div>
      <ChakraProvider>
        <Routes>
          <Route path='/checkout/:priceplan' element={<SubCheckOut />}></Route>
          <Route path='/return' element={<Return />}></Route>
          <Route path='/ppoutput/:sub_id/:user_name/:service_id' element={<PaypalOutput />}></Route>
        </Routes>
      </ChakraProvider>
    </div>
  );
}

export default App;
